import isEmpty from 'lodash.isempty'
import { compile, match } from 'path-to-regexp'
import qs from 'qs'
import slugify from 'slugify'

import IntlService from '~/services/IntlService'

import { sorting } from '~/data/options'

import { SearchFormValues } from '~/models/Search'

import { capitalizeFirstLetter, isOfType } from './functions'

const Paths = {
  auth: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up'
  },
  sell: {
    index: '/sell'
  },
  search: {
    index: '/search',
    buildSearch({
      by_type,
      category: _category,
      make: _make,
      ...values
    }: SearchFormValues) {
      const newQuery: any = {}
      const category = _category || null
      const make = _make || null

      if (!!category || !!category?.length) {
        const currentCategory = Array.isArray(category) ? category : [category]
        if (currentCategory.length) {
          newQuery.category = currentCategory.join('|')
        }
      }

      if (!!make && !!make.name) {
        const currentMake = isOfType.object(make) ? make : { name: make }
        newQuery.make = make.model
          ? Object.values(currentMake)?.join('~~')
          : Object.values(currentMake)?.join('')
      }

      const newValues = { ...values, ...newQuery }

      const pathName = `${this.index}${
        by_type ? `/${by_type}` : ''
      }${qs.stringify(newValues, {
        skipNulls: true,
        addQueryPrefix: !isEmpty(newValues),
        arrayFormat: 'brackets'
      })}`

      return pathName
    },
    parseSearch(path = '') {
      const [pathName, query] = path.split('?')
      // @ts-ignore
      const { params = {} } = match('/search/:by_type', {
        decode: decodeURIComponent
      })(pathName)

      const { category: _category, make: _make, ...values } = qs.parse(query)

      const newQuery: any = {}
      const category = _category || null
      const make = _make || null

      if (category) {
        newQuery.category = String(category || '').split('|') || null
      }

      if (make) {
        const [makeQuery, modelQuery, seriesQuery] = String(make).split('~~')
        const modelName = modelQuery && String(modelQuery).split(',')
        const seriesName = seriesQuery && String(seriesQuery).split(',')
        const makeName = String(makeQuery).split(',')

        newQuery.make = { name: makeName, model: modelName, series: seriesName }
      }

      return { ...params, ...values, ...newQuery } as SearchFormValues
    },
    formatSearch(
      path = ''
    ): {
      key: string
      value: any
      formatKey: string
      formatValue: string
      defaultValue: any
    }[] {
      const queries = this.parseSearch(path)
      const formatArr = Object.entries(queries).map(([key, value]) => {
        const formattedSearch = {
          key,
          value,
          formatKey: capitalizeFirstLetter(
            key.replace('by_', '').replace(/_/, ' ')
          ),
          formatValue: value,
          defaultValue: null
        }

        switch (key) {
          case 'price':
            return {
              ...formattedSearch,
              formatValue: `${IntlService.compactFormat(
                +value[0]
              )} - ${IntlService.compactFormat(+value[1])}`
            }
          case 'odometer':
            return {
              ...formattedSearch,
              formatValue: `${IntlService.numberFormat(
                +value[0]
              )} km - ${IntlService.numberFormat(+value[1])} km`
            }
          case 'sorting':
            return {
              ...formattedSearch,
              formatValue: `${sorting?.[value as string]}`
            }
          case 'make':
            return {
              ...formattedSearch,
              formatValue: [value?.name.join(', '), value?.model, value?.series]
                .filter(Boolean)
                .join(' | ')
            }
          case 'by_type':
            return {
              ...formattedSearch,
              formatKey: 'Category'
            }
          case 'length':
            return {
              ...formattedSearch,
              formatKey: 'Length, m',
              formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
            }
          case 'length_feet':
            return {
              ...formattedSearch,
              formatKey: 'Length, ft',
              formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
            }
          case 'boat_trailer_length':
            return {
              ...formattedSearch,
              formatKey: 'Boat trailer length, m',
              formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
            }
          case 'boat_trailer_length_feet':
            return {
              ...formattedSearch,
              formatKey: 'Boat trailer length, ft',
              formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
            }
          case 'boat_draft':
            return {
              ...formattedSearch,
              formatKey: 'Boat draft, m',
              formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
            }
          default:
            break
        }

        if (Array.isArray(value)) {
          return {
            ...formattedSearch,
            formatValue: value.join(' | '),
            formatKey: key === 'category' ? 'Subcategory' : key
          }
        }
        if (value?.min || value?.max) {
          return {
            ...formattedSearch,
            formatValue: [value?.min, value?.max].filter(Boolean).join(' - ')
          }
        }

        return formattedSearch
      })

      if (!queries.sorting) {
        formatArr.push({
          key: 'sorting',
          value: 'newest',
          formatKey: 'Sorting',
          formatValue: sorting.newest,
          defaultValue: null
        })
        return formatArr
      }
      return formatArr
    }
  },
  details: {
    index: '/details',
    buildDetails: compile<{ slug: string }>('/details/:slug')
  },
  congratulation: {
    index: '/congratulation'
  },
  account: {
    index: '/account',
    dashboard: '/account/dashboard',
    ads: '/account/manage-ads',
    buildEditAd: compile<{ id: string | number }>('/account/manage-ads/:id'),
    adPerformance: '/account/ad-performance',
    enquiries: '/account/enquiry-report',
    savedItems: '/account/saved-items',
    savedSearches: '/account/saved-searches',
    profile: '/account/profile'
  },
  businessess: {
    index: '/businesses',
    buildBusiness: compile<{ slug: string | number }>('/businesses/:slug'),
    new: '/businesses/new',
    edit: '/businesses/edit'
  },
  dealer: {
    index: '/dealer',
    buildDealer: compile<{ slug: string | number }>('/dealer/:slug'),
    new: '/dealer/new',
    edit: '/dealer/edit'
  },
  newsReviews: {
    index: '/news-reviews',
    buildArticle(id: string | number, name: string) {
      return [this.index, slugify([id, name?.toLowerCase()].join(' '))].join(
        '/'
      )
    },
    buildCategory: compile<{ category: string }>(
      '/news-reviews/category/:category'
    ),
    buildSubcategory: compile<{ category: string; subcategory: string }>(
      '/news-reviews/category/:category/:subcategory'
    ),
    getCategory: (path: string) => {
      // @ts-ignore
      const { params } = match<{
        params: { category: string }
      }>('/news-reviews/category/:category/:subcategory?', {
        decode: decodeURIComponent
      })(path)
      return params?.category
    },
    buildSearch(params?: {
      keywords?: string
      search?: string
      featuredOnly?: boolean
      category?: string
    }) {
      return `/news-reviews/search${qs.stringify(params, {
        addQueryPrefix: !!params
      })}`
    }
  },
  becomeDealer: {
    index: '/become-a-dealer'
  },
  package: {
    index: '/package'
  },
  help: {
    index: '/help'
  },
  terms: {
    index: '/terms'
  },
  privacy: {
    index: '/privacy'
  },
  contactUs: {
    index: '/contact-us'
  },
  advertising: {
    index: '/advertise-with-us'
  },
  partnerships: {
    index: '/partnerships'
  },
  stratton: {
    index: '/finance-enquire'
  },
  otherWebsites: {
    caravanWorld: 'https://caravanworld.com.au/',
    camperAustralia: 'https://campertraileraustralia.com.au/',
    hemaMaps: 'https://www.hemamaps.com/'
  }
}

export default Paths
